<template>
  <div class="e-form e-form--account">
    <el-form ref="registerForm" :model="registerForm" method="post">
      <input v-model="registerForm.username" type="hidden" name="username" />

      <!-- Errormessages -->
      <el-alert
        v-for="(item, index) in errors.messages"
        :key="index"
        :title="$t(index)"
        type="error"
        v-html="$t(item)"
      />
      <!-- /Errormessages -->

      <el-alert
        v-if="errorWithLink"
        :title="$t('system.attention')"
        type="error"
      >
        {{ errorWithLink.prepend }}
        <router-link :to="{ name: errorWithLink.routeName }">
          {{ errorWithLink.linkText }}
        </router-link>
        {{ errorWithLink.append }}
      </el-alert>
      <!-- /Errormessages -->

      <!-- Successmessage -->
      <el-alert
        v-if="formSuccess"
        :title="$t('messages.user.changePassword.success')"
        type="success"
      />
      <!-- /Errormessages -->

      <!-- Row -->
      <el-form-item :label="$t('system.password.old')" prop="oldPassword">
        <el-input
          v-model="registerForm.oldPassword"
          :placeholder="$t('system.password.old')"
          type="password"
          name="oldPassword"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.password.new')" prop="newPassword">
        <el-input
          v-model="registerForm.newPassword"
          :placeholder="$t('system.password.new')"
          type="password"
          name="newPassword"
        />
      </el-form-item>

      <!-- Row -->
      <el-form-item :label="$t('system.password.new')" prop="newPassword2">
        <el-input
          v-model="registerForm.newPassword2"
          :placeholder="$t('system.password.newRepeat')"
          type="password"
          name="newPassword2"
        />
      </el-form-item>

      <!-- Row -->
      <div class="e-form__row e-form__row--submit">
        <div />
        <el-button
          :loading="loading"
          type="tertiary"
          native-type="submit"
          @click.prevent="submitForm('registerForm')"
        >
          {{ $t('system.save') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { userActions } from 'mixins'

export default {
  mixins: [userActions],
  data() {
    return {
      loading: false,
      registerForm: {
        username: '',
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      icons: {
        exclamation: require('assets/icons/basic-exclamation.svg'),
        check: require('assets/icons/arrows-check.svg')
      },
      errors: [],
      errorWithLink: false,
      formSuccess: false
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    this.registerForm.username = this.userInfo.username
  },
  methods: {
    submitForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.sendForm(formName)
          } else {
            return false
          }
        })
      }
    },

    async sendForm(formName) {
      this.loading = true
      this.formSuccess = false
      this.errors = []
      this.errorWithLink = false

      try {
        const response = await this.__user__changePassword(this.registerForm)

        if (response.status === 200) {
          this.formSuccess = true
        }
      } catch (error) {
        if (error.messages[0] !== 'messages.user.account.unconfirmed') {
          this.errors = error
        } else {
          this.errorWithLink = {
            prepend: this.$t('messages.user.account.unconfirmed.prepend'),
            linkText: this.$t('messages.user.account.unconfirmed.linkText'),
            routeName: 'resendConfirmMail',
            append: this.$t('messages.user.account.unconfirmed.append')
          }
        }
      } finally {
        this.resetForm(formName)
        this.loading = false
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.registerForm.username = this.userInfo.username
    }
  }
}
</script>
